import React, { useContext, useEffect, useRef, useState } from "react"
import { PageProps } from "gatsby"
import tw from "twin.macro"
import { Layout } from "../components/atoms/Layout"
import { LocationContext } from "../contexts/Location"
import { Card } from "../components/atoms/Card"
import Icon, { Language, Loader } from "../components/atoms/Icon"
import { Select, Option } from "../components/atoms/Select"
import { Dealer as DealerType } from "../global"
import { setRadius, setSelectedDealer } from "../contexts/Location/actions"
import { DealerMap } from "../components/molecules/DealerMap"
import { Dealer } from "../components/molecules/Dealer"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import useTealiumEvent from "../hooks/Tealium/useTealiumEvent"
import useDealers from "../hooks/useDealers"
import useDealerSearch from "../hooks/Tealium/useDealerSearch"
import { useTealiumContext } from "../contexts/Tealium"
import { SEO } from "../components/atoms/SEO"
import { LocalStorageClient } from "../clients/LocalStorageClient"
import { ConfirmZipContext } from "../contexts/ConfirmZip"
import { openConfirmZipModal } from "../contexts/ConfirmZip/actions"
import { LocationSelector } from "../components/molecules/LocationSelector"
import { LanguageContext } from "../contexts/Language"
import useTealiumNoZip from "../hooks/Tealium/useTealiumNoZip"
import { StarRating } from "../components/atoms/StarRating"
import Paginate from "../components/molecules/DealerMap/Pagination"
import useViewportSize from "../hooks/useViewportSize"
import usePagination from "../hooks/usePagination"
import { TabGroup } from "../components/atoms/TabGroup"
import TabsSection from "../components/organisms/MobileTireService/TabsSection"
import { css } from "@emotion/react"
import {
  detectMobileView,
  getIndexOfNestedArrays,
  sliceArrayIntoArrays,
} from "../helpers"
import { scrollIntoView } from "seamless-scroll-polyfill"
import { ConfirmZipModal } from "../components/molecules/ConfirmZipModal"
import { motion } from "framer-motion"

// lmAnalyticsId prop is for the ObservePoint id for the "Learn More" button
// If it is not provided, a default value is supplied
const DealerCard: React.FC<{
  dealer: DealerType
  i: number
  lmAnalyticsId?: string
  selected: boolean
  onClick: () => void
}> = ({
  dealer,
  i,
  lmAnalyticsId = null,
  selected,
  onClick,
  ...remainingProps
}) => {
  const ref = useRef(null)
  useEffect(() => {
    const dealerCard = ref?.current
    if (dealerCard && selected) {
      dealerCard.scrollIntoView(false)
    }
  }, [selected])

  return (
    <li ref={ref}>
      <Card
        shadowLevel={selected ? 5 : 3}
        css={[
          tw`bg-white mb-2 md:(mx-4) border-2 border-transparent`,
          selected && tw`border-2 border-red-400`,
        ]}
        {...remainingProps}
      >
        <Dealer
          dealer={dealer}
          i={i}
          onClick={onClick}
          collapsable={false}
          lmAnalyticsId={lmAnalyticsId}
        />
      </Card>
    </li>
  )
}

const DealersPage: React.FC<PageProps> = ({ data, location }: any) => {
  const [
    {
      zip,
      city,
      state,
      radius,
      dealersLoading,
      dealers,
      selectedDealer,
      latLng,
      isOutOfRange,
    },
    dispatch,
  ] = useContext(LocationContext)

  const { language, _ } = useContext(LanguageContext)
  // Values for Tealium logic
  const { trackTealEvent } = useTealiumEvent()
  const { getDealerInfo } = useDealers()
  const dealerData = getDealerInfo()
  const { dealerSearch } = useDealerSearch()
  const firstLoad = useRef(true)
  const { handleTealView } = useTealiumView()
  const { tealPageData } = useTealiumContext()
  const [isOutOfArea] = useTealiumNoZip()
  const [selectedMobileView, setSelectedMobileView] = useState("list")
  const tabAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
    content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const focusAnimation = css`
    &:focus-visible:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
  content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:focus-visible:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const activeStyles = css`
    ${tw`font-semibold focus-visible:(border-transparent)`}
    &:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
    &:hover:after {
      ${tw`h-1 bg-red-400`}
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
  `

  // End - Values for Tealium logic

  useEffect(() => {
    // Tealium view event to fire when user is out of area, and no zip has been manually set
    // Looks at "user_ip" property because the "value" property is reset to false even when out of area (see: out of area modal logic)
    // Only fire the view event on first page render
    if (isOutOfArea && firstLoad.current) {
      firstLoad.current = false
      handleTealView({
        page_name: "fyd homepage",
        tealium_event: "find_dealer",
        page_type: "find your dealer",
        dealer_name: "NOT SET",
        dealer_code: "NOT SET",
        search_results: 0,
      })
    }
    if (!dealers?.length) return

    //  ** Tealium Logic ** //

    // Tealium - get fresh details after `dealers` update
    const dealerInfo = getDealerInfo()

    // On first page load, and only _once dealers value is updated_, a "view" event is fired
    // For subsequent renders, no Tealium events are fired unless manually triggered by a user interaction
    if (firstLoad.current) {
      firstLoad.current = false
      handleTealView({
        page_name: "fyd homepage",
        tealium_event: "find_dealer",
        page_type: "find your dealer",
        dealer_name: dealerInfo.dealerNames,
        dealer_code: dealerInfo.dealerCodes,
        search_results: dealerInfo.searchResults,
      })
    }
  }, [dealers, isOutOfArea])

  // ** End Tealium logic ** //

  // Show Confirm Zip Modal if zip has not been confirmed
  const [{}, dispatchConfirmZip] = useContext(ConfirmZipContext)
  useEffect(() => {
    const zipIsConfirmed = LocalStorageClient.read("confirmedZip")
    if (zipIsConfirmed != true) {
      dispatchConfirmZip(openConfirmZipModal())
    }
  }, [])

  const radiusOptions = [25, 50, 100]
  const [moreDealers, setMoreDealers] = useState(true)
  const [nextRadius, setNextRadius] = useState(1)

  useEffect(() => {
    if (!radius) return

    const nextRadius = radiusOptions.indexOf(radius) + 1

    setNextRadius(nextRadius)

    if (nextRadius >= radiusOptions.length) {
      setMoreDealers(false)
    } else {
      setMoreDealers(true)
    }
  }, [radius])

  const mapOffset = 90
  const mapHeight = typeof window !== "undefined" && `80vh`
  // Function depends on dealers search data when radius changes
  const handleTealRadiusEvent: (radius: number, value: number) => void = (
    radius,
    value
  ) => {
    const refinement_value = `${tealPageData?.page_name}|${tealPageData?.page_type}|Miles|${value}`
    if (radius === Number(value)) {
      // if the radius doesn't change:
      trackTealEvent({
        tealium_event: "refinement",
        refinement_value,
        dealer_name: dealerData.dealerNames,
        dealer_code: dealerData.dealerCodes,
        search_results: dealerData.searchResults,
      })
    } else {
      // If the radius does change, there is special handling because of state changes:
      dealerSearch("refinement", {
        refinement_value,
        dealer_name: dealerData.dealerNames,
        dealer_code: dealerData.dealerCodes,
      })
    }
  }

  // Set the selected Dealer
  useEffect(() => {
    const params = new URLSearchParams(location?.search)
    const dealerId = params?.get("selectedDealer")
    if (dealerId) {
      dispatch(setSelectedDealer(Number(dealerId)))
    } else if (dealers) {
      dispatch(setSelectedDealer(dealers[0]?.Id))
    }
  }, [])

  // ** Start Pagination Logic ** //

  const [currentPage, setCurrentPage] = useState(1)
  const [dealersPerPage, setDealersPerPage] = useState(null)
  const [currentDealers, setCurrentDealers] = useState(null)
  const { height, width } = useViewportSize()
  const { handleNext, handlePrev, handleJumpTo, maxPage } = usePagination(
    dealers,
    dealersPerPage,
    currentPage,
    setCurrentPage
  )
  const [mobileView, setMobileView] = useState(false)
  const [showPagination, setShowPagination] = useState(null)

  // Calculate number of dealers per page
  useEffect(() => {
    const isMobileView = detectMobileView()
    setMobileView(isMobileView)
    if (!mobileView) {
      if (dealers?.length <= 4) {
        setDealersPerPage(4)
        setShowPagination(false)
      } else {
        setDealersPerPage(4)
        setShowPagination(true)
      }
    } else if (mobileView) {
      setDealersPerPage(3)
      if (dealers?.length > 3) setShowPagination(true)
    }
  }, [height, width, dealers])

  // Determine dealers displayed for current page
  useEffect(() => {
    if (currentDealers?.length == 0 && dealers?.length !== 0) {
      setCurrentPage(1)
      const indexOfLastPost = currentPage * dealersPerPage
      const indexOfFirstPost = indexOfLastPost - dealersPerPage
      setCurrentDealers(dealers?.slice(indexOfFirstPost, indexOfLastPost))
    } else if (dealers?.length > 0 && currentPage && dealersPerPage) {
      const indexOfLastPost = currentPage * dealersPerPage
      const indexOfFirstPost = indexOfLastPost - dealersPerPage
      setCurrentDealers(dealers?.slice(indexOfFirstPost, indexOfLastPost))
    }
  }, [dealers, dealersLoading, currentPage, dealersPerPage])

  // Paginate to the selected Dealer
  useEffect(() => {
    if (dealers?.length > 0 && selectedDealer && dealersPerPage) {
      //slice dealers by page location in case of select dealer is not on current page
      const slicedDealers = sliceArrayIntoArrays(dealers, dealersPerPage)

      mobileView && setSelectedMobileView("list")

      if (slicedDealers !== undefined) {
        const pageNumOfSelected =
          getIndexOfNestedArrays(slicedDealers, selectedDealer) + 1
        //handle decrease in results
        if (currentPage > maxPage) {
          setCurrentPage(1)
          //navigate to selected dealer page
        } else if (!dealersLoading) {
          setCurrentPage(pageNumOfSelected)
        } else {
          setCurrentPage(1)
        }
      }
    }
  }, [selectedDealer, dealers])

  //reset pagination if dealers change after initial load
  useEffect(() => {
    if (currentDealers && dealersLoading && !dealers) {
      setCurrentDealers(null)
    }
  }, [dealersLoading])

  // ** End Pagination Logic ** //

  // Add a state variable for tracking the current radius

  const handleExpandRadius = () => {
    if (radius === 25) {
      dispatch(setRadius(50))
    } else if (radius === 50) {
      dispatch(setRadius(100))
    }
  }
  const customSchema = `{
    "@context": "http://schema.org",
    "@type": "AutomotiveBusiness",
    "name": "Local Southeast Toyota Dealers",
    "description": "Find your local Toyota dealer. Southeast Toyota Dealers cover 177+ Toyota dealers in Florida, Georgia, Alabama, North Carolina, and South Carolina. Connect with a Toyota Dealer near you!",
    "image": "https://cdn.sanity.io/images/90qxor39/production/89b2cb42278e75cf11fd0a1995960794eacfe3ff-335x158.png",
    "logo": "https://cdn.sanity.io/images/90qxor39/production/cd0d47fee6c6d265d584179267e02dc77fb966b7-900x350.svg",
    "url": "https://exploresetoyota.com/dealers/",
    "sameAs": ["https://twitter.com/ExploreToyota","https://www.facebook.com/ExploreToyota","https://www.youtube.com/c/LocalToyotaDealers","https://www.instagram.com/explore.toyota/","https://www.pinterest.com/ExploreToyota/"]
  }`
  return (
    <Layout tealium={{ label: "dealersPage" }}>
      <SEO
        url={location.href}
        title={"Find a Toyota Dealer | Explore Toyota"}
        keywords={""}
        schema={customSchema}
        description={
          "Find your local Toyota dealer. Southeast Toyota Dealers cover 177+ Toyota dealers in Florida, Georgia, Alabama, North Carolina, and South Carolina."
        }
        canonicalPath={"dealers/"}
      />
      <ConfirmZipModal />
      <section
        aria-label="Find Dealers Section"
        css={[tw`bg-black text-white pt-6 px-4 lg:(px-16 py-4)`]}
        id="dealerTop"
      >
        <div css={[tw`grid  grid-cols-3 `]}>
          <div css={[tw`col-span-3 lg:(col-span-1)`]}>
            <h1
              css={[
                tw`text-[1.5rem] font-light tracking-widest text-center`,
                tw`lg:(max-w-xs mr-3.5 text-3xl text-left)`,
              ]}
            >
              {_("Toyota Dealers Near Me")}
            </h1>
          </div>
          <div
            css={[
              tw`col-span-3`,
              tw`lg:(col-span-2)`,
              language === "es" && tw`lg:(mt-12) lg:(mt-0)`,
            ]}
          >
            <LocationSelector
              textColor="white"
              focusColor={"gray"}
              analyticsId="location"
            />
          </div>
        </div>
      </section>
      <section
        aria-label="Dealers List & Map Section"
        css={[tw`bg-gray-100 relative lg:h-full`]}
      >
        <div css={[tw`absolute w-full flex justify-center z-50`]}>
          <div
            css={[
              tw`text-center p-2 text-sm bg-black inline rounded-b-xl px-6 shadow-3 lg:(ml-80)`,
            ]}
          >
            <span css={[tw`font-semibold text-gray-50`]}>
              {dealers?.length || 0}
            </span>{" "}
            <span css={[tw`text-gray-400`]}>{_("Dealers within")}</span>
            <span css={[tw`px-2`]}>
              <Select
                red
                small
                value={[`${radius}`, `${radius} ${_("miles")}`]}
                onChange={([value, label]) => {
                  dispatch(setRadius(Number(value))) // Update the radius in the state
                  handleTealRadiusEvent(radius, value)
                }}
              >
                {/* TODO: Show "miles" with the new "label" prop */}
                {radiusOptions.map((m, i) => (
                  <Option
                    value={m.toString()}
                    label={`${m} miles`}
                    analytics-id={`miles filter:location:${i + 1}`}
                  />
                ))}
              </Select>
            </span>
            <span css={[tw`text-gray-400`]}>{_("of")}</span>{" "}
            <span css={[tw`font-semibold text-gray-50`]}>{zip}</span>
          </div>
        </div>
        <div
          css={[tw`grid grid-cols-12 grid-flow-row w-auto h-full lg:(h-auto)`]}
        >
          {/* START MOBILE */}
          <div css={[tw`block relative col-span-12 lg:hidden`]}>
            <TabGroup css={[tw`w-full`]}>
              {({ changeTab }) => {
                return (
                  <div
                    css={[
                      tw`pt-20 absolute z-20 flex justify-center w-full h-32 shadow-3 bg-gray-50`,
                    ]}
                  >
                    <button
                      css={[
                        tabAnimation,
                        focusAnimation,
                        tw`relative flex-nowrap whitespace-nowrap px-0 mx-6 py-2 cursor-pointer flex uppercase`,
                        tw`focus-visible:(outline-gray)`,

                        selectedMobileView == "list" && activeStyles,
                      ]}
                      onClick={() => {
                        setSelectedMobileView("list"), changeTab(0)
                      }}
                    >
                      {"List View"}
                    </button>
                    <button
                      css={[
                        tabAnimation,
                        focusAnimation,
                        tw`relative flex-nowrap whitespace-nowrap px-0 mx-6 py-2 cursor-pointer flex uppercase`,
                        tw`focus-visible:(outline-gray)`,
                        selectedMobileView == "map" && activeStyles,
                      ]}
                      onClick={() => {
                        setSelectedMobileView("map"), changeTab(1)
                      }}
                    >
                      {"Map View"}
                    </button>
                  </div>
                )
              }}
            </TabGroup>
            {selectedMobileView == "list" && (
              <div>
                {dealers?.length > 0 ? (
                  <div
                    css={[
                      tw`col-span-12 flex flex-col mt-36 h-full align-top md:mx-10 lg:(hidden)`,
                    ]}
                  >
                    {currentDealers?.length > 0 ? (
                      <DealerCardsWithPaginated
                        dealers={dealers}
                        selectedDealer={selectedDealer}
                        dispatch={dispatch}
                        radius={radius}
                        currentDealers={currentDealers}
                        dealersPerPage={dealersPerPage}
                        setSelectedDealer={setSelectedDealer}
                        currentPage={currentPage}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                        handleJumpTo={handleJumpTo}
                        maxPage={maxPage}
                        showPagination={showPagination}
                        handleExpandRadius={handleExpandRadius}
                      />
                    ) : (
                      <DealerCardLoader />
                    )}
                  </div>
                ) : dealersLoading ? (
                  <DealerCardLoader />
                ) : (
                  <NoDealersState isMobile={true} />
                )}
              </div>
            )}

            <div css={[tw`hidden`, selectedMobileView == "map" && tw`block`]}>
              <div
                css={[
                  dealers?.length > 0
                    ? tw`relative h-[80vh] landscape:(h-auto) mt-32`
                    : `h-[80vh];`,
                ]}
              >
                {dealersLoading && (
                  <div
                    css={[
                      tw`absolute top-0 left-0 right-0 bottom-0 bg-gray-400 z-10 bg-opacity-70 flex h-full items-center justify-center`,
                      `h-[80vh];`,
                    ]}
                  >
                    <div>
                      <Icon.Loader
                        color="red-400"
                        css={[tw`max-h-32 animate-spin mx-auto`]}
                      />
                      <span
                        css={[
                          tw`block mt-4 text-lg text-gray-700 font-bold text-center leading-tight`,
                        ]}
                      >
                        {_("Fetching updated dealer information.")}
                      </span>
                    </div>
                  </div>
                )}
                {dealers?.length > 0 ? (
                  <DealerMap
                    dealers={dealers}
                    center={latLng}
                    allowPan={true}
                  />
                ) : (
                  <NoDealersState isMobile={true} />
                )}
              </div>
            </div>
          </div>
          {/* END MOBILE */}

          <div
            css={[
              dealers?.length > 0
                ? tw`hidden lg:(flex flex-col pt-4 col-span-5 h-full) xl:(col-span-4)`
                : tw`hidden`,
            ]}
          >
            {currentDealers?.length > 0 ? (
              <DealerCardsWithPaginated
                dealers={dealers}
                selectedDealer={selectedDealer}
                dispatch={dispatch}
                radius={radius}
                currentDealers={currentDealers}
                dealersPerPage={dealersPerPage}
                setSelectedDealer={setSelectedDealer}
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
                handleJumpTo={handleJumpTo}
                maxPage={maxPage}
                showPagination={showPagination}
                handleExpandRadius={handleExpandRadius}
              />
            ) : (
              <DealerCardLoader />
            )}
          </div>
          <div
            css={[
              dealers?.length > 0
                ? tw`hidden lg:(inline col-span-7 bg-gray-300 relative overflow-hidden) xl:(col-span-8)`
                : tw`col-span-12 `,
            ]}
          >
            <div css={[tw`sticky h-full min-h-[400px]`]}>
              <div css={[tw`relative h-full min-h-full`]}>
                {dealersLoading && (
                  <div
                    css={[
                      tw`absolute top-0 left-0 right-0 bottom-0 bg-gray-400 z-10 bg-opacity-70 flex items-center justify-center bg-gray-300`,
                    ]}
                  >
                    <div>
                      <Icon.Loader
                        color="red-400"
                        css={[tw`max-h-32 animate-spin mx-auto`]}
                      />
                      <span
                        css={[
                          tw`block mt-4 text-lg text-gray-700 font-bold text-center leading-tight`,
                        ]}
                      >
                        {_("Fetching updated dealer information.")}
                      </span>
                    </div>
                  </div>
                )}
                {dealers?.length > 0 ? (
                  <DealerMap
                    center={latLng}
                    dealers={dealers}
                    allowPan={true}
                  />
                ) : (
                  <div
                    css={[
                      dealersLoading
                        ? tw`hidden`
                        : tw`block relative col-span-12 h-full`,
                    ]}
                  >
                    <NoDealersState />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default DealersPage

const DealerCardLoader = () => {
  return (
    <ul css={[tw`h-full`]}>
      <Icon.Loader color="red-400" css={[tw`max-h-32 animate-spin mx-auto`]} />
    </ul>
  )
}

const DealerCardsWithPaginated: React.FC<{
  dealers: DealerType[]
  selectedDealer: DealerType
  setSelectedDealer: any
  dispatch: any
  radius: number
  currentDealers: DealerType[]
  dealersPerPage: number
  currentPage: number
  handleNext: () => void
  handlePrev: () => void
  handleJumpTo: any
  maxPage: number
  showPagination: boolean
  handleExpandRadius: () => void
  value?: number
}> = ({
  dealers,
  selectedDealer,
  setSelectedDealer,
  dispatch,
  radius,
  currentDealers,
  dealersPerPage,
  currentPage,
  handleJumpTo,
  handleNext,
  handlePrev,
  maxPage,
  showPagination,
  handleExpandRadius,
  value,
}) => {
  const isLastPage = currentPage === maxPage
  const { _ } = useContext(LanguageContext)
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()
  const { getDealerInfo } = useDealers()
  const dealerData = getDealerInfo()
  const { dealerSearch } = useDealerSearch()
  // Function depends on dealers search data when radius changes
  const handleTealRadiusEvent: (radius: number, value: number) => void = (
    radius,
    value
  ) => {
    const refinement_value = `${tealPageData?.page_name}|${
      tealPageData?.page_type
    }|expand|${radius === 25 ? 50 : 100}`
    if (radius === Number(value)) {
      // if the radius doesn't change:

      trackTealEvent({
        tealium_event: "refinement",
        refinement_value,
        dealer_name: dealerData.dealerNames,
        dealer_code: dealerData.dealerCodes,
        search_results: dealerData.searchResults,
      })
    } else {
      // If the radius does change, there is special handling because of state changes:

      dealerSearch("refinement", {
        refinement_value,
        dealer_name: dealerData.dealerNames,
        dealer_code: dealerData.dealerCodes,
      })
    }
  }

  return (
    <div css={[tw`h-full flex flex-col place-content-between pb-4`]}>
      <ul>
        {currentDealers?.map((dealer, i) => {
          return (
            <DealerCard
              key={dealer.Id}
              dealer={dealer}
              i={dealers?.indexOf(dealer) + 1}
              selected={dealer?.Id === selectedDealer?.Id}
              onClick={() => {
                dispatch(setSelectedDealer(dealer.Id))
              }}
            />
          )
        })}
      </ul>
      <div css={[tw`flex content-center justify-center items-center`]}>
        {showPagination && (
          <Paginate
            itemsPerPage={dealersPerPage}
            totalDealers={dealers?.length}
            previousPage={handlePrev}
            nextPage={handleNext}
            currentPage={currentPage}
            handleJumpTo={(page: number) => handleJumpTo(page)}
            radius={radius}
            maxPage={maxPage}
          />
        )}
        {isLastPage && (radius === 25 || radius === 50) && (
          <motion.button
            animate={{ opacity: 1, x: 0 }}
            initial={{ opacity: 0, x: 30 }}
            transition={{ duration: 0.5, delay: 1 }}
            onClick={() => {
              handleExpandRadius()
              handleTealRadiusEvent(radius, value)
            }}
            analytics-id={`expand filter:location:${radius === 25 ? 1 : 2}`}
            css={[
              tw`hidden w-44 h-8 bg-white my-4 flex-nowrap whitespace-nowrap content-center rounded-md normal-case font-semibold justify-center items-center text-sm shadow-2 cursor-pointer hover:(bg-red-400 text-white)`,
              isLastPage && tw`flex`,
            ]}
          >
            {radius === 25 ? _("Expand to 50 miles") : _("Expand to 100 miles")}
            <Icon.Chevron css={[tw`ml-1 mt-1 h-2 w-2`]} />
          </motion.button>
        )}
      </div>

      <div css={[tw`flex justify-center font-semibold`]}>
        {dealers?.length} {_("Dealers within")} {radius} {_("Miles")}
      </div>
    </div>
  )
}

const NoDealersState = ({ isMobile = false }) => {
  const { _ } = useContext(LanguageContext)
  return (
    <div
      css={[
        isMobile
          ? tw`h-[80vh] mt-36 flex justify-center items-center w-full mx-auto text-center bg-gray-50`
          : tw`hidden lg:(flex justify-center items-center w-full h-[60vh] mx-auto text-center bg-gray-50)`,
        `background-image: url(${process.env.GATSBY_ROOT_URL}map-bg.png)`,
        `background-repeat: no-repeat;`,
        `background-position:center;`,
        `@media only screen and (max-width: 600px)  {background-size: contain; height:400px;}`,
      ]}
    >
      <div css={[tw`w-4/5`, tw`md:(w-2/3)`, tw`lg:(w-1/3)`]}>
        {_("Sorry, we couldn't find any dealers in your selected area. ")}
        {_("Please try expanding your search area, or enter a new location.")}
      </div>
    </div>
  )
}
