import React, { useEffect } from "react"
import tw from "twin.macro"
import Icon from "../../atoms/Icon"
import DealersPage from "../../../templates/dealers"

const Paginate: React.FC<{
  itemsPerPage: number
  totalDealers: number
  previousPage: any
  nextPage: any
  currentPage: number
  handleJumpTo: any
  radius: number
  maxPage: number
}> = ({
  itemsPerPage,
  totalDealers,
  previousPage,
  nextPage,
  currentPage,
  handleJumpTo,
  radius,
  maxPage,
}) => {
  const numOfButtons = 3
  const pageNumbers: number[] = []
  const pagesNeeded = Math.ceil(totalDealers / itemsPerPage)

  var start = Math.max(0, currentPage - 4)
  var end = Math.min(start + numOfButtons, pagesNeeded - 1)

  for (var i = start; i <= end; i++) {
    pageNumbers.push(i + 1)
  }

  const isLastPage = currentPage === maxPage // Check if it's the last page

  return (
    <div css={[tw`w-auto flex justify-center px-2 my-5`]}>
      {/* <div
        css={[
          tw`col-span-2 font-semibold flex self-end justify-center order-3 mt-4 text-base lg:(col-span-1 order-1 mt-0 justify-end text-sm) xl:(text-base)`,
        ]}
      >
        {`${totalDealers} Dealers within ${radius} Miles`}
      </div> */}
      <ul
        css={[
          tw`flex self-end gap-2 col-span-2 order-2 justify-center lg:(col-span-1 justify-start)`,
        ]}
      >
        <li onClick={previousPage} className="group" tabIndex={0}>
          <Icon.PaginationButton
            direction="left"
            css={[tw`h-8 w-8`]}
            inactive={currentPage == 1}
          />
        </li>
        {pageNumbers.map((number: number) => (
          <li
            key={number}
            onClick={() => handleJumpTo(number)}
            css={[
              tw`w-8 h-8 bg-white rounded-md flex justify-center items-center text-sm shadow-2 cursor-pointer hover:(bg-red-400 text-white)`,
              number == currentPage &&
                tw`bg-red-400 text-white hover:(bg-red-600)`,
            ]}
            tabIndex={0}
          >
            {number}
          </li>
        ))}
        {(isLastPage && radius === 100) || !isLastPage ? (
          <li onClick={nextPage} className="group" tabIndex={0}>
            <Icon.PaginationButton
              direction="right"
              css={[tw`h-8 w-8`]}
              inactive={isLastPage && radius === 100}
            />
          </li>
        ) : null}
      </ul>
    </div>
  )
}

export default Paginate
