import React from "react"
import { scrollIntoView } from "seamless-scroll-polyfill"

const usePagination = (
  data: any[],
  itemsPerPage: number,
  currentPage: number = 1,
  setCurrentPage: (page: number) => any
) => {
  const maxPage = Math.ceil(data?.length / itemsPerPage)

  const scrollToTop = () => {
    if (window && typeof window !== "undefined") {
      const element = document.getElementById(`dealerTop`)
      if (element) {
        // Built in .scrollIntoView HTML DOM  Method (with smooth behavior) is not supported in Safari - using polyfill plugin as workaround
        scrollIntoView(element, {
          behavior: "smooth",
          inline: "start",
        })
      }
    }
  }

  const getCurrentData = () => {
    const begin = Math.max(0, currentPage - 4)
    const end = Math.min(begin + itemsPerPage, maxPage - 1)
    return data.slice(begin, end)
  }

  const handleNext = () => {
    const nextPage = Math.min(currentPage + 1, maxPage)
    setCurrentPage(nextPage)
    scrollToTop()
  }

  const handlePrev = () => {
    const prevPage = Math.max(currentPage - 1, 1)
    setCurrentPage(prevPage)
    scrollToTop()
  }

  const handleJumpTo = (page: number) => {
    const pageNumber = Math.max(1, page)
    setCurrentPage(Math.min(pageNumber, maxPage))
    scrollToTop()
  }

  return {
    handleNext,
    handlePrev,
    handleJumpTo,
    getCurrentData,
    currentPage,
    maxPage,
  }
}

export default usePagination
